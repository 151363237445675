<template>
  <div class="big">
    <div class="login-container">
      <div class="obox"></div>
      <div class="box">
        <el-form
          :model="myForm"
          :rules="myRule"
          status-icon
          ref="myForm"
          label-position="left"
          label-width="0px"
          class="demo-ruleForm login-page"
        >
          <img src="@/assets/images/3.png" />
          <h3 class="title">欢迎！</h3>
          <el-form-item prop="username">
            <p>账号</p>
            <el-input
              type="text"
              v-model="myForm.username"
              auto-complete="off"
              placeholder="用户名"
            />
          </el-form-item>
          <el-form-item prop="password">
            <p>密码</p>
            <el-input
              type="password"
              v-model="myForm.password"
              auto-complete="off"
              placeholder="密码"
              @keyup.enter.native="handleSubmit"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSubmit" :loading="logining"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
        <div class="image">
          <img src="@/assets/images/1.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logining: false,
      myForm: {
        username: "",
        password: "",
      },
      myRule: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      checked: false,
    };
  },
  methods: {
    async handleSubmit() {
      const { data: res } = await this.$axios.post(
        "/api/admin/login",
        this.myForm
      );
      if (res.code === 200) {
        if (res.data === -1) {
          this.$message.success(res.message);
        } else {
          localStorage.setItem("userId", res.data.userId);
          this.$store.dispatch("login", res.data).then((path) => {
            this.$router.push(path);
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.big {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 953px;
  background-image: url("~@/assets/images/photo.jpg");
  background-repeat: no-repeat;
}
.login-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.obox {
  width: 100px;
  height: 100px;
}
.box {
  width: 1200px;
  position: relative;
  margin: 100px auto;
  .image {
    width: 800px;
    height: 600px;
    float: left;
  }
}
.login-page {
  width: 360px;
  height: 560px;
  padding: 20px;
  float: left;
  background: url("~@/assets/images/2.png");
  h3 {
    font-size: 24px;
    line-height: 33px;
    color: #fff;
    font-weight: bold;
  }
  .el-form-item {
    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 20px;
      color: #fff;
      margin-top: 0;
    }
    .el-input {
      input {
        color: #fff;
        font-size: 14px;
        background: none;
        border: 1px solid #c1d4e6;
      }
      input:hover {
        border: 1px solid #9cc1e6;
      }
    }
  }
}
label.el-checkbox.rememberme {
  margin: 0px 0px 15px;
  text-align: left;
}
</style>

